<template>
  <b-card class="mb-3">
    <router-link :to="{ name: 'MetricsReportingframework', params: { id: item.id }}"><h5 class="card-title">{{item.name}}</h5></router-link>
    <div>
      {{ item.definition }}
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'ReportingframeworkSimple',
  props: ['item'],
  components: {
  },
  computed: {
  },
  created: function () {
  },
  data () {
    const data = {
      filter: ''
    }
    return data
  },
  methods: {
  }
}
</script>
